// If you want to override variables do it here
@import 'font';
@import 'variables';
@import '~animate.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '~react-toggle/style.css';

//Custom
@import 'table';
@import 'button';
@import 'form-controls';
@import 'modal';
@import 'custom';
@import 'scrollbar';

.active-button {
  color: $light;
  background-color: $success;
  border-color: $success;
}

.ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-align-center {
  text-align: center !important;
}

.disable-element {
  pointer-events: none;
  opacity: 0.4;
  box-shadow: none;
}

.clipOverFlow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.my-dropdown-toggle {
  .dropdown-toggle {
    // padding: 0px !important;
    background-color: transparent;
    color: #5b7583;
    font-size: 1.2rem;
    padding: 4px 6px 4px;
    font-family: 'font-regular';
    font-size: 1rem;
    border-radius: 0;
  }
  .btn-light:not(:disabled):not(.disabled):active,
  .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
  }
  .btn-light:focus,
  .btn-light.focus,
  .btn-light:hover {
    border-color: transparent !important;
  }
}
