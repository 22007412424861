@import 'variables';
html {
  font-size: 14px;
  scroll-behavior: smooth;
}
@media (max-width: 767px) {
  html {
    font-size: 10px;
  }
}

#root {
  height: 100%;
  background: $page-bg-color;
}

body {
  margin: 0;
  height: 100vh;
  color: $color-base;
  .page-padding {
    padding: 2rem;
  }
}

.app {
  height: 100%;
  display: flex;
  position: relative;
}

//CUSTOM
input:focus,
.btn:focus {
  outline: none !important;
  //border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.pointer {
  cursor: pointer !important;
}
.pointer-none {
  cursor: none !important;
}

.rounded-badge {
  padding: 3px 12px;
  border-radius: 20px;
  font-family: font-bold;
  border: solid 1px #7036c9;
  color: #7036c9;
  background-color: #faf7ff;
}

//page title style
h4 {
  font-family: font-semibold;
  font-size: 1.556rem;
  font-weight: normal;
  margin-bottom: 5px;
}
h5 {
  font-family: font-medium;
  font-size: 1.111rem;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
.display-label {
  margin-bottom: 10px;
  .form-label {
    font-family: font-semibold;
  }
}

//card
.card-panel {
  border-radius: 7px;
}
.bg-grey {
  // background-color: #f5f5f5;
  background: $bg-light-color;
}
.bg-green-light {
  background-color: rgba(191, 200, 207, 0.22);
}

//show label
.label {
  color: $label-gray;
  font-family: $font-family-base;
}
.badge.rounded {
  border-radius: 24px !important;
  width: 23px;
  height: 23px;
  line-height: 15px;
  border: 1px solid #dcdcdc;
}
//tost
.Toastify__toast-container {
  width: auto;
  .Toastify__toast {
    min-height: 51px;
    min-width: 37vw;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.border-radius {
  border-radius: 10px;
}
.border-radius-sm {
  border-radius: 5px;
}
.mandatory-astrik {
  color: red;
}

#popover-deviation {
  min-width: 540px;
}

.input-kg-view {
  background-color: white;
  border-left: none;
}

.badge-label {
  margin: 5px;
  padding: 4px;
  line-height: 11px;
  margin-bottom: 3px;
  border-radius: 4px;
  margin-right: 5px;
  font-weight: 700;
  // letter-spacing: 0.1em;
  white-space: unset !important;
}

.dash-card {
  background: #f8faff 0% 0% no-repeat padding-box;
  border: 1px solid #d3daea;
  border-radius: 10px;
  opacity: 1;
  width: auto;
  padding: 2.3rem;
  height: 100%;
  .row {
    height: 100%;
  }
}

@media only screen and (max-width: 1224px) {
  .search-field {
    padding-left: 6px;
    background-image: url('../img/search-icon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    max-width: 100%;
    background-color: #f0f3f5;
    font-weight: normal;
    background-origin: content-box;
    border-color: #dedede;
    background-position: right center;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000000;
      opacity: 0.4;
    }
  }
}

.nav-pills {
  .nav-item {
    padding-right: 1rem;
  }

  .nav-link {
    background-color: transparent;
    color: $color-base;
    font-size: 1.2rem;
    padding: 4px 6px 4px;
    font-family: 'font-regular';
    font-size: 1rem;
    border-radius: 0;
    &.active {
      color: $dark;
      background-color: transparent;
      border-bottom-width: 2px;
      font-family: 'font-medium';
      border-bottom-style: solid;
      border-bottom-color: #328e69;
    }
  }
  &.small {
    .nav-item {
      padding-right: 1rem;
    }
    .nav-link {
      font-size: 1rem;
    }
  }
}
.line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
